import { FC, PropsWithChildren } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { getPriceFromCentimes } from '@les-sherpas/sherpas-toolbox';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip, Typography } from '@mui/material';

import Button from '@/designSystem/Button/Button';
import useNavigation from '@/shared/Hooks/useNavigation';
import useOpenClose from '@/shared/Hooks/useOpenClose';

import messages from './messages';

import useStyles from './styles';

type Props = {
  price: number;
};

const IncomeResult: FC<PropsWithChildren<Props>> = ({ price }) => {
  const { formatMessage } = useIntl();
  const { classes } = useStyles();
  const { replaceTo, urls } = useNavigation();
  const {
    isOpen: isTooltipOpen,
    open: openTooltip,
    close: closeTooltip,
  } = useOpenClose({ isOpenByDefault: false });

  const clickHandler = () => {
    window.dataLayer.push({ event: 'teacher_register_step1' });
    replaceTo(urls.givePrivateCourse({ signup: 'true' }));
  };

  return (
    <div className={classes.incomeResult}>
      <div className={classes.estimateContainer}>
        <div className={classes.tooltipContainer}>
          <Typography variant="title2Xs" component="span">
            <FormattedMessage {...messages.estimate} />
          </Typography>
          <Tooltip
            className={classes.grey}
            placement="top"
            open={isTooltipOpen}
            onClose={closeTooltip}
            onClick={openTooltip}
            title={<FormattedMessage {...messages.helper} />}
          >
            <InfoOutlinedIcon aria-label="info" />
          </Tooltip>
        </div>
        <Typography variant="titleXs" component="span">
          <FormattedMessage
            {...messages.price}
            values={{
              price: getPriceFromCentimes(price),
              grey: (chunks) => (
                <Typography
                  variant="title2Xs"
                  component="span"
                  className={classes.grey}
                >
                  {chunks}
                </Typography>
              ),
            }}
          />
        </Typography>
      </div>
      <Button
        variant="primary"
        onClick={clickHandler}
        label={formatMessage(messages.button)}
        isFullWidth
      />
    </div>
  );
};

export default IncomeResult;
