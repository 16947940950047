import { FC, PropsWithChildren, ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Advantage from '@/components/GiveCourses/AdvantageSherpas/Advantage/Advantage';
import Button from '@/designSystem/Button/Button';
import Hidden from '@/generic/Hidden/Hidden';
import useNavigation from '@/shared/Hooks/useNavigation';

import messages from './messages';

import useStyles from './styles';

type Props = {
  title: string | ReactElement;
  subtitle: string | ReactElement;
};

const AdvantageSherpas: FC<PropsWithChildren<Props>> = ({
  title,
  subtitle,
}) => {
  const { classes } = useStyles();
  const isMobile = !useMediaQuery(useTheme().breakpoints.up('sm'));
  const { replaceTo, urls } = useNavigation();
  const { formatMessage } = useIntl();

  return (
    <article className={classes.rootAvantageSherpas}>
      <Typography variant="titleLg" className={classes.title}>
        <FormattedMessage {...messages.title} />
      </Typography>
      <Typography variant="body2xl" component="p" className={classes.subTitle}>
        <Hidden variant="md">{title}</Hidden>
        <Hidden isUp variant="md">
          {subtitle}
        </Hidden>
      </Typography>
      <div className={classes.gridContainer}>
        <Advantage
          imageUrl="https://sherpas-uploads.s3.eu-west-3.amazonaws.com/Landing/img-prof-02.jpg"
          alt="Cours à domicile ou en ligne"
          title={<FormattedMessage {...messages.cardTitle1} />}
          subtitle={<FormattedMessage {...messages.cardDescription1} />}
        />
        <Advantage
          imageUrl="https://sherpas-uploads.s3.eu-west-3.amazonaws.com/Landing/img-prof-04.jpg"
          alt="Étudiants qui s’entraident en cours"
          title={<FormattedMessage {...messages.cardTitle2} />}
          subtitle={<FormattedMessage {...messages.cardDescription2} />}
        />
        <Advantage
          imageUrl="https://sherpas-uploads.s3.eu-west-3.amazonaws.com/Landing/img-prof-01.jpg"
          alt="Gagner de l’argent en donnant des cours particuliers"
          title={<FormattedMessage {...messages.cardTitle3} />}
          subtitle={
            <FormattedMessage
              {...messages.cardDescription3}
              values={{ isMobile }}
            />
          }
        />
      </div>

      <Button
        size="large"
        variant="primary"
        onClick={() => {
          replaceTo(
            urls.givePrivateCourse({
              signup: 'true',
            })
          );
        }}
        label={formatMessage(messages.button)}
      />
    </article>
  );
};
export default AdvantageSherpas;
