import { FC, PropsWithChildren, ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@mui/material';

import Button from '@/designSystem/Button/Button';
import useNavigation from '@/shared/Hooks/useNavigation';
import { useIsMobile } from '@/utils/useResponsive';
import Features from '@Landing/Systems/Features/Features';
import LandingStepper from '@Landing/Systems/LandingStepper/LandingStepper';

import messages from './messages';

import useStyles from './styles';

type HowToBecomeSherpasSection = {
  title: string | ReactElement;
  subtitle: string | ReactElement;
};

type Props = {
  title: string | ReactElement;
  subtitle: string | ReactElement;
  featureBackground: string;
  featureLabel: string | ReactElement;
  howToSections: HowToBecomeSherpasSection[];
};

const HowToBecomeSherpas: FC<PropsWithChildren<Props>> = ({
  title,
  subtitle,
  featureBackground = 'https://sherpas-uploads.s3.eu-west-3.amazonaws.com/Landing/img-hero-teacher.jpg',
  featureLabel,
  howToSections,
}) => {
  const { classes } = useStyles();
  const { formatMessage } = useIntl();
  const { replaceTo, urls } = useNavigation();
  const isMobile = useIsMobile();

  return (
    <article className={classes.howToBecomeSherpas}>
      <div className={classes.titleContainer}>
        <Typography variant="titleLg" className={classes.title}>
          {title}
        </Typography>
        <Typography
          variant="body2xl"
          component="p"
          className={classes.subTitle}
        >
          {subtitle}
        </Typography>
      </div>
      <Features backgroundColor="indigo" className={classes.feature}>
        <Features.BackgroundImage
          url={featureBackground}
          alt="Image professeur sherpa"
          className={classes.imageContainer}
        >
          <div className={classes.gradient} />
          <Typography
            variant="titleSm"
            component="span"
            className={classes.imageTitle}
          >
            {featureLabel}
          </Typography>
        </Features.BackgroundImage>
        <LandingStepper className={classes.stepper}>
          {howToSections?.map(({ subtitle, title }, i) => (
            <LandingStepper.Container key={i}>
              <LandingStepper.Container.Path
                stepNumber={i + 1}
                isWithOutDot={i === howToSections.length - 1}
              />
              <LandingStepper.Container.Step
                title={title}
                subtitle={subtitle}
              />
            </LandingStepper.Container>
          ))}
          <Button
            size="large"
            onClick={() =>
              replaceTo(
                urls.givePrivateCourse({
                  signup: 'true',
                })
              )
            }
            label={formatMessage(messages.button)}
            isFullWidth={isMobile}
          />
        </LandingStepper>
      </Features>
    </article>
  );
};

export default HowToBecomeSherpas;
